<template>
  <b-form-group
    label="TC Kimlik No / Vergi No"
    label-for="taxno"
  >
    <validation-provider
      #default="{ errors }"
      name="TC Kimlik No / Vergi No"
      rules="required"
    >
      <b-form-input
        id="taxno"
        v-model="form.taxNo"
        placeholder="TC Kimlik No / Vergi No"
        :state="errors.length > 0 ? false:null"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'CustomerTaxNo',
  components: { BFormInput, ValidationProvider, BFormGroup },
}
</script>
