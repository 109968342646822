<template>
  <b-form-group
    label="Vergi Dairesi"
    label-for="taxoffice"
  >
    <validation-provider
      #default="{ errors }"
      name="Vergi Dairesi"
      rules="required"
    >
      <b-form-input
        id="taxoffice"
        v-model="form.taxOffice"
        placeholder="Vergi Dairesi"
        :state="errors.length > 0 ? false:null"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'CustomerTaxOffice',
  components: { BFormInput, ValidationProvider, BFormGroup },
}
</script>
