<template>
  <b-row>
    <b-col>
      <customer-company />
    </b-col>
    <b-col>
      <customer-tax-no />
    </b-col>
    <b-col>
      <customer-tax-office />
    </b-col>
    <b-col>
      <customer-phone />
    </b-col>
    <b-col>
      <customer-address />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CustomerCompany from '@/views/Core/Users/elements/CustomerCompany.vue'
import CustomerTaxNo from '@/views/Core/Users/elements/CustomerTaxNo.vue'
import CustomerTaxOffice from '@/views/Core/Users/elements/CustomerTaxOffice.vue'
import CustomerPhone from '@/views/Core/Users/elements/CustomerPhone.vue'
import CustomerAddress from '@/views/Core/Users/elements/CustomerAddress.vue'

export default {
  name: 'CustomerForm',
  components: {
    BRow,
    BCol,
    CustomerCompany,
    CustomerTaxNo,
    CustomerTaxOffice,
    CustomerPhone,
    CustomerAddress,
  },
}
</script>
