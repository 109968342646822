<template>
  <b-form-group
    label="Adres"
    label-for="address"
  >
    <b-form-textarea
      id="address"
      v-model="form.address"
      placeholder="Adres"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'CustomerAddress',
  components: { BFormTextarea, BFormGroup },
}
</script>
