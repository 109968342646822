<template>
  <b-form-group
    label="Telefon"
    label-for="phone"
  >
    <b-input-group>
      <b-input-group-prepend is-text>
        TR (+90)
      </b-input-group-prepend>
      <cleave
        id="phone"
        v-model="form.phone"
        class="form-control"
        :raw="true"
        :options="options.phone"
        placeholder="224 224 00 55"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'

export default {
  name: 'CustomerPhone',
  components: { BInputGroupPrepend, BInputGroup, BFormGroup },
}
</script>
